import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-botox.jpg'

class BotoxVerona extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query BotoxVeronaMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Botox Verona, NJ | ${data.site.siteMetadata.title}`} description={`Verona botox treatment uses safe injections of botulinum toxin to minimize the appearance of fine lines, wrinkles, and more.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Botox Verona, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>The stigma behind Botox has all but disappeared. Over 8 million Americans have received Botox each year since 2019. This number should continue to rise as complications are minimized and the costs spent on self-care continue to rise.</p>

                                    <p>Botox is one of the most popular cosmetic solutions for combating the negative effects of aging. Verona botox treatment uses safe injections of botulinum toxin to minimize the appearance of fine lines, wrinkles, and more.</p>

                                    <p>Our Optimal Health and Wellness doctor, Dr. Sambataro, uses the most effective, efficient, and modern practices for Botox procedures in Verona. Our goal is to make sure you are completely satisfied with your procedure and attain your aesthetic goals. Dr. Sambataro also does in-home Botox for Verona patients, a convenient solution that doesn’t even require a trip to the doctor!</p>

                                    <h2>What is Botox used for?</h2>

                                    <p>Botox is primarily used to both improve the negative effects of aging and the prevention of future effects. The “big three” issues that botox seeks to resolve are:</p>

                                    <ul>
                                        <li>crow’s feet</li>
                                        <li>forehead creases</li>
                                        <li>laugh lines</li>
                                    </ul>

                                    <p>Verona botox can minimize the appearance of wrinkles and fine lines, revealing a more youthful appearance and delivering a substantial boost in self-confidence. Botox is also a fantastic solution for the prevention of lines and wrinkles. The process prevents muscles from moving in the face, therefore minimizing the increase of lines and wrinkles that are caused by muscle contraction through facial expressions, laughter, smiling, etc.</p>

                                    <p>Botox is also used in a few ways that you may not expect! Botox can be used to treat:</p>

                                    <ul>
                                        <li>uncontrollable blinking</li>
                                        <li>chronic migraines</li>
                                        <li>excessive underarm sweating</li>
                                        <li>involuntary muscle spasms (generally found in the shoulder and neck)</li>
                                    </ul>

                                    <p>If you want to treat or prevent wrinkles and fine lines, or have one of the unique issues listed above, reach out to Dr. Sambataro in Verona today for a consultation!</p>

                                    <h2>How does Botox work?</h2>

                                    <p>Botox works by injecting small amounts of the solution into targeted muscles, effectively immobilizing the muscle so that fine lines and wrinkles are diminished.</p>

                                    <p>Pain is extremely minimal for this procedure, and many describe the process as less painful than a normal shot, or comparable to a mild bee sting.</p>

                                    <p>The procedure is relatively short, usually only taking a few minutes from start to finish, depending on the amount of injection sites. No anesthesia is required, although most doctors will use a topical numbing cream.</p>

                                    <h2>What is the timeline for results and recovery?</h2>

                                    <p>The single, most important part of the recovery process is within the first 24 hours. It is essential that you refrain from rubbing the injection site, as you do not want the botox solution to spread away from the site and into nearby regions. You’ll also want to sleep on your back and slightly elevated, being sure to avoid pressure and rubbing of the face during your sleep. Finally, avoid any high-intensity exercise for a few days. Spend a few days relaxing, allowing the Botox to do its job without any hindrance.</p>

                                    <p>Our Verona botox takes approximately 7 to 14 days for patients to see the full effect of the procedure. We generally recommend a final check-in after 14 days to simply confirm that everything is as it should be. At this point, you will be fully ready to show off your new, youthful appearance!</p>

                                    <h2>What side effects exist with Botox?</h2>

                                    <p>We’ve all been exposed to tv shows and movies that show botched Botox procedures, or may even know someone personally who had an unsuccessful procedure that left them with a drooping eye or excessive inflammation.</p>

                                    <p>The good news is, with a reputable Verona doctor like Dr. Sambataro, side effects are drastically minimized. A good doctor using up-to-date procedural techniques should lead to an effective botox procedure that simply reveals a youthful appearance without side effects.</p>

                                    <p>That being said, potential side effects for botox are as follows:</p>

                                    <ul>
                                        <li>numbness</li>
                                        <li>muscle weakness</li>
                                        <li>drooping smile or eyelid</li>
                                        <li>chronic headaches</li>
                                        <li>lasting inflammation</li>
                                        <li>bruising</li>
                                    </ul>

                                    <p>Most side effects, if any are experienced, should be gone within 24-48 hours of the procedure. However, as mentioned, a reputable doctor will greatly minimize the risk of side effects.</p>

                                    <h2>At-home Botox</h2>

                                    <p>Dr. Sambataro provides botox solutions in the comfort of your own home, delivering the same solution to your doorstep. In-home botox provides an additional confidence in your procedure, allowing you to feel comfortable and secure. It also provides a heightened convenience, as you can schedule your Verona botox without having to factor in the drive to and from your doctor’s office.</p>

                                    <h2>What is my next step for Botox?</h2>

                                    <p>The first step you can take is to schedule a consultation with Optimal Health and Wellness in Verona. We can walk through the botox process, discuss the risks and side effects, and field any questions or concerns you may have. <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>Call today</a> and see if botox is the right solution for you and your aesthetic goals!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default BotoxVerona